form.form {
  margin: 1rem 0;
}

input,
textarea,
select {
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 0.4rem;
  margin: 0.6rem 0;
  padding: 0.4rem 0.6rem;
}

.tlbx input[type=text],
.tlbx select {
  margin: 0;
}

label {
  margin: 0;
  display: block;
  margin-bottom: -0.4rem;
}

input.has-error {
  border-color: #c00;
}

.field .description {
  margin: -5px 0 0;
  color: #777;
  line-height: 1.4;
}

input:focus {
  box-shadow: #00b0ff61 0 0 0 4px;
}

.field {
  position: relative;
  margin: 0 0 1rem;
  border-radius: 0.4rem;
}

.field input,
.field select,
.field textarea {
  width: 100%;
}

.field input[type=checkbox] {
  width: 30px;
}

.field.checkbox label{
  background: #fff;
  margin: 0.6rem 0;
}

.field .error {
  background: 0 0;
  line-height: 1.2;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  color: #c00;
}

.tlbx {
  background: #fff;
  padding: 6px;
  border-radius: 10px;
}

.btn,
button {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  background: #fff;
  line-height: 1;
  border: 2px solid;
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.07) 0 0 0 3px;
  white-space: nowrap;
  color: #111;
}

.btn-block {
  display: block;
}

.btn:disabled,
button:disabled {
  color: #ddd;
  box-shadow: none;
  cursor: not-allowed;
}

button[disabled] svg {
  fill: #ccc;
}

.btn.lg,
button.lg {
  font-size: 1.4rem;
  padding: 0.6rem 1.5rem;
}

.btn.btn-sm {
  padding: 0.2rem 0.5rem;
  font-size: 14px;
  font-family: Inter, sans-serif;
}

.btn.un-btn {
  border: 0;
  padding: 0.2rem;
  margin: 0;
  box-shadow: none;
}

.btn+.btn,
button+button {
  margin-left: 0.4rem;
}

.btn-verify {
  background: #ffeb3b;
  background-image: linear-gradient(-12deg, transparent, #ff9800);
}

.btn-upgrade {
  background: #cddc39;
  background-image: linear-gradient(-12deg, transparent, #009688);
  color: #fff;
}

.btn-delete {
  color: #c00;
}