.progress-bar {
  background: var(--chroma);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: #29292947 0 2px 6px;
  position: relative;
}

/*.css-progress-stepped .progress-bar {
  background: linear-gradient(45deg, #855103, #c5960a);
}*/

.progress-bar-flex { 
  display: flex;
}

.progress-bar-inner {
  background: linear-gradient(45deg, #d68308, #ffc107);
  height: 30px;
  display: flex;
  align-items: center;
  padding: 6px;
}

.progress-bar-step {
  background-image: linear-gradient(45deg, #504f4e, #eae9e7);
  background-blend-mode: overlay;
  height: 30px;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  text-align: center;
  display: flex;
}

.progress-bar-step span {
  font-size: 10px;
  padding-left: 5px
}

.progress-bar-text {
  color: #fff;
  position: absolute;
  left:0;
  right:0;
  text-align:center;
}


.css-progress-label{font-size: 14px;padding:3px}
