@media (min-width: 768px) {
  .nav-menu {
    display: inline-block;
    margin: 0 20px;
    max-width: 520px;
    overflow: hidden;
    line-height: 1;
  }

  .nav-menu a {
    color: #d3e2ef;
    font-size: 24px;
    text-transform: uppercase;
    padding: 4px;
    font-weight: 400;
    letter-spacing: -1px;
    white-space: nowrap;
  }

  .nav-menu a:hover {
    color: #fff;
  }
}

.dw {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -280px;
  width: 280px;
  visibility: visible;
  overflow-y: auto;
  background-color: #fff;
  color: #333;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
}

.dw a {
  font-weight: 400;
  color: #2196f3;
}

.dw-nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dw-nav a,
.f nav a {
  text-transform: uppercase;
  display: block;
  padding: 0.5rem 1rem;
  line-height: 1.1;
  margin: 0;
  border-top: 1px solid rgb(255 255 255/15%);
}

.dw-nav a:hover {
  color: #fff;
  background: #2196f3;
}

#dw-cb:checked + .dw {
  left: 0;
  z-index: 1000;
  box-shadow: #000 0 0 23px 2px;
}

#dw-cb:checked ~ .o {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 900;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

label.o-x {
  display: block;
  width: 100%;
  height: 100%;
}

.dw-hdr {
  height: 54px;
  background: #fff;
  display: flex;
  align-items: center;
  align-content: space-between;
}

a.logo {
  color: #000;
  font-size: 24px;
  padding: 12px;
  flex: 1;
}

label.x {
  text-align: right;
  padding: 8px;
  line-height: 12px;
  font-size: 2rem;
  color: #000;
}

img.avatar {
  border-radius: 50px;
  margin-left: 6px;
}
