/* You can add global styles to this file, and also import other style files */
@import url(base.css);
@import url(fonts.css);
@import url(layout.css);
@import url(dataview.css);
@import url(forms.css);
@import url(navbar.css);
@import url(dashboard.css);
@import url(css-progress.css);

body{ background: #f2f1ef }

body .page {
  background: #fff;
  padding-top: 15px;
  padding-bottom: 50px;
  min-height: calc(100% - 28px);
  box-shadow: #0000002e 0 5px 10px;
}

a.btn:active,
a.btn:focus {
  color: #111;
}

.inv {
  color: #aaa;
}

.inv a {
  color: #def;
}

.inv .btn,
.inv button {
  color: #eee;
  border-color: #777;
}

.body, .text {
  line-height: 1.5;
}

.nb {
  background-color: transparent;
  padding: 0 10px;
  height: 60px;
  color: #777;
  box-shadow: #0000001c 0 2px 8px;
  position: fixed;
  top:0;left:0;right:0;
  background:#fff;
  z-index: 60;
}

body{ padding-top: 80px }

.nb>.flex{height:60px}

.nb.development {
  background: #009688;
}

.nb #logo {
  margin-right: 8px;
  height: 50px;
}

.nb a {
  color: #ef6c00;
}

.nb .flex {
  align-content: center;
  align-items: center;
}

.nb button {
  color: #fff;
}

@media (min-width: 768px) {
  .nb {
    height: 80px;
    padding: 0 20px;
    position: static;
    margin-bottom: 15px;
  }
  .nb>.flex{height:80px}
  body{ padding-top: 0; }
}

.brand {
  font-size: 28px;
  display: inline-block;
}

.brand:focus,
.brand:hover {
  text-decoration: underline;
}

a.brand + span {
  position: relative;
  top: 18px;
  font-size: 11px;
  left: -18px;
  color: #1682ef;
}

.call .btn {
  height: 30px;
  line-height: 26px;
  padding: 0 15px;
}

.nb .user {
  display: flex;
  align-items: center;
}

.nb .user .name {
  margin-left: 8px;
}

.user .photo img,
.user .initials {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  background: #ffc107;
  color: #000;
}

.nb .dw-tgl {
  text-indent: -999px;
  width: 30px;
  height: 30px;
  position: relative;
}

.nb .dw-tgl:after {
  content: "=";
  display: block;
  font-size: 28px;
  text-align: center;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  text-indent: 0;
}

button.mat-menu-item {
  box-shadow: none;
  height: 38px;
  line-height: 36px;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}

/*! login / register */

.act.flex {
  background: #f2f2f2;
  align-items: center;
}

.mat-form-field-flex {
  background: #fff9c4;
}

span.mat-form-field-label-wrapper {
  font-size: 16px;
}

/*@media screen and (min-width:1080px) {
  .bx.p0.w460.mx-auto.mt.mb {
    margin-top: -30px;
  }  
}*/

/*! profile */
.main h1 {
  border-bottom: 5px solid #eee;
  margin: 0 0 0.6rem;
  padding: 4px 6px;
  position: relative;
}

h1 .btn {
  position: absolute;
  right: 0;
  padding: 0.3rem 0.8rem;
}

/*! editor */
.pane.full {
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  padding: 20px;
  top: 50px;
  bottom: 0;
  overflow: scroll;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  background: #000;
  color: #fff;
}

/*! image uploader */
.img-preview-container {
  min-height: 180px;
  position: relative;
  background: #333;
}

.img-preview {
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/*! hero */
.hero {
  padding: 1rem;
}

.hero .body h1 {
  font-size: 2rem;
}

.lead {
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.hero a.cta {
  color: #000;
  background: #ffeb3b;
  box-shadow: rgb(0 0 0) 0 0 0 4px;
}

.bx .hdr {
  border-radius: 9px 9px 0 0;
}

@media screen and (min-width: 768px) {
  .hero {
    padding: 1rem 1.5rem;
  }

  .hero .body h1 {
    font-size: 3rem;
  }

  .hero .lead {
    font-size: 1.4rem;
  }
}

.call {
  margin: 14px;
  /*font-style: oblique;*/
  padding: 30px;
  color: #333;
  line-height: 1.3;
  border-radius: 30px 0;
  background-color: #c9f8a5;
  background-image: linear-gradient(35deg, #96fbc4, #f9f586);
  box-shadow: 2px 4px 10px rgb(0 0 0 / 33%), 9px 6px #98fbc2;
}

.call p {
  line-height: 1.2;
}

.call .btn {
  background-color: #fff;
}

.call .btn:hover {
  color: #000;
}

/*! listing */
.price {
  font-weight: 700;
  font-size: 1.2rem;
  color: #e91e63;
}

.flex.bar {
  background: #000;
  color: #eee;
}

.flex.bar li {
  margin-right: 0.4rem;
}

.flex.bar li:last-child {
  margin-right: 0;
}

.flex.bar a {
  color: #fff;
}

/*! details */
ul.crmbs {
  background: #eee;
  border-radius: 8px;
  box-shadow: inset #00000040 0 0 2px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

ul.crmbs li {
  list-style: none;
  font-size: 1rem;
  line-height: 1;
}

ul.crmbs li a {
  padding: 0.4rem 0.6rem;
  color: #000;
  display: block;
  border-radius: 6px;
  margin-right: 4px;
  background: #fff;
  box-shadow: #00000040 0 0 2px;
}

.post .feat {
  min-height: 180px;
  background: #333;
  position: relative;
}

.post h1 {
  border: 0;
  font-size: 1.4rem;
  padding: 0;
  border-bottom: 2px solid #ccc;
}

ul.table {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eee;
}

ul.table li {
  line-height: 1;
  border: 1px solid #eee;
  border-width: 0 1px 1px 1px;
  padding: 0.4rem 0.5rem;
}

ul.table li strong {
  min-width: 116px;
  display: inline-block;
}

.bx.info {
  border: 2px solid #ffeb3b;
}

.bx.info h2 {
  background: #ffeb3b;
  padding: 0.4rem 0.6rem;
  margin: -1px;
  border-radius: 8px 8px 0 0;
}

/*! footer */
.foo {
  background: #222;
  color: #eee;
  padding: 15px 15px 50px;
  margin: -1px;
}

.foo a {
  color: #def;
  text-decoration: underline;
}

/*! overwrites */

.mat-form-field {
  font-family: "Source Sans Pro";
}

.postForm .mat-form-field,
.userform .mat-form-field {
  display: block;
}

.postForm textarea {
  min-height: 100px;
}

.mat-menu-trigger {
  cursor: pointer;
}

.mat-menu-content button + button {
  margin: 0;
}

/*! dark theme */
/*
@media (prefers-color-scheme: dark) {
  body {
    background: #222;
    color: #aaa;
  }

  body .page {
    background: #222;
    color: #ccc;
  }

  body .bx {
    background: #444;
    border: 1px solid #777;
    color: #ddd
  }

  a,
  h3 a.url {
    color: #def;
  }

  .btn,
  button {
    color: #aaa;
  }

  .act .btn {
    color: #eee;
  }

  .bx.info {
    border-color: #96880c
  }

  .bx.info h2 {
    background: #96880c;
    color: #eee
  }

  ul.table,
  ul.table li {
    border-color: #777
  }

  .well {
    background: #111
  }

  .act.flex {
    background: #9a5c00
  }
}
*/
