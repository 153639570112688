.dropMenu {
  position: relative;
  cursor: pointer;
}

.subMenu {
  display: none;
}

.subMenu a {
  display: block;
  margin: 8px;
  padding: 6px 0;
  border-bottom: 1px solid #eee;
  font-size: 16px;
}

.dropMenu.open .subMenu {
  display: block;
  position: absolute;
  top: 100%;
  background: #fff;
  width: 100%;
  box-shadow: #00000025 0px 5px 4px;
  border-radius: 0 0 10px 10px;
}
