h3 .flag {
  font-size: 1rem;
  color: #e91e63;
}

.site {
  border: 2px solid #eee;
  transition: border-color 0.2s ease-in-out;
}

.site h3 {
  color: #999;
}

.site.verified {
  border: 2px solid #4caf50;
}

.site.verified h3 a {
  color: #000;
}

.site.paused {
  box-shadow: none;
  border-color: #8cd9e3;
}

.site.locked {
  background: #eceff1;
}

.site.verified.favorite {
  border-width: 3px;
}

.site.verified.has-error {
  border: 2px solid #f44336;
  background: #ffecee;
}

.site .domain {
  color: #999;
}

.site .err {
  word-break: break-word;
}

.page-stat .cell {
  font-size: 12px;
  padding: 6px 6px;
}

.page-stat .url {
  word-break: break-word;
}

.status {
  width: 38px;
  text-align: center;
}

.status-na {
  background-color: #eee;
  color: #aaa
}

.status-200 {
  background: #b3e5fc;
}

.status-301 {
  background: #ffe0b2;
}

.status-302 {
  background: #fff59d;
}

.status-403 {
  background: #ffc107;
  color: #000;
}

.status-404 {
  background: #ffcdd2;
  color: #700;
}

.status-500 {
  background: #ab0000;
  color: #fff;
}

.cache-na {
  background: #eee;
  color: #aaa;
}

.cache-miss {
  background: #ffcdd2;
  color: #700;
}

.cache-expired {
  background: #ffeccd;
  color: #583f16;
}

.cache-hit {
  background: #aed581;
}

.statsWrap {
  padding: 4px;
  background: #f6f6f6;
  border-radius: 12px;
}

.counter {
  border: 2px solid #d1cec3;
  line-height: 1;
  text-align: center;
  background: #e7e5dc;
  padding: 0.5rem;
  min-width: 3.6rem;
  border-radius: 8px;
}

.counter + .counter {
  margin-left: 0.4rem;
}

.counter .value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.counter .label {
  font-size: 0.8rem;
  color: #000;
}

.site .counter .value {
  font-size: 25px;
}

.site .counter .label {
  font-size: 14px;
}

.page-stat {
  border: 1px solid #eee;
  padding: 3px 6px;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
}

.page-stat:hover {
  box-shadow: rgba(108, 108, 108, 0.5) 0 1px 4px;
  border-color: #fff;
}

.page-stat a {
  color: #02e;
}

.page-stat .cell {
  margin-right: 4px;
  white-space: nowrap;
  font-size: 13px;
}

.page-stat .time {
  color: #333;
  width: 40px;
  background: #f6f6f6;
  text-align: center;
}

.cell.br {
  border-radius: 0.25rem;
}

.help {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.css-gauge {
  padding: 10px;
  width: 140px;
}

.gauge {
  position: relative;
  border-radius: 50%/100% 100% 0 0;
  background: linear-gradient(90deg, #ff9800, #7ddb11);
  overflow: hidden;
}

.gauge:before {
  content: "";
  display: block;
  padding-top: 50%;
}

.gauge .chart {
  overflow: hidden;
}

.gauge .mask {
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 0;
  top: 40%;
  background-color: #fff;
  border-radius: 50%/100% 100% 0 0;
}

.gauge .percentage {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: 0;
  right: -1px;
  background-color: var(--chroma, #aaa);
  transform: rotate(var(--rotation));
  transform-origin: bottom center;
  transition-duration: 600;
}

.gauge .value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.gauge .value {
  font-size: 23px;
  font-weight: 700;
  color: #8a8a8a;
}

.gauge .value .sign {
  font-size: 12px;
}

.gauge .min {
  position: absolute;
  bottom: 0;
  left: 5%;
}

.gauge .max {
  position: absolute;
  bottom: 0;
  right: 5%;
}

.css-progress {
  margin: 6px 0;
}

.dashboard .css-progress {
  min-width: 300px;
}

.site-limit {
  width: 90px;
}

.btn-dismiss {
  font-size: 24px;
  background: #e5e5e5;
  border: 0;
  box-shadow: none;
  border-radius: 50%;
  line-height: 24px;
  width: 24px;
  padding: 0;
  box-sizing: border-box;
  height: 24px;
}

.note {
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 6px;
  border-radius: 6px;
}

.note .date {
  font-size: 13px;
  color: #777;
}

.note .title {
  font-weight: 700;
  margin: 3px 0;
}

.note .summary {
  font-size: 13px;
}

.site-header {
  margin: -20px -15px 10px;
  padding: 6px;
}

@media (min-width:600px) {
  .site-header{margin: 0 0 10px;padding: 0;}
}

.site-header .btn-icon {
  width: 40px;
  height: 40px;
}

.site-header .btn-icon svg {
  width: 34px;
  height: 34px;
}

h1.url {
  word-break: break-word;
}

.switcher {
  height: 40px;
  overflow: hidden;
  position: relative;
  z-index: 40;
  border: 1px solid #bbb;
  border-radius: 5px;
  max-width: 400px;
  margin-right: 6px;
}

.switcher-icon {
  position: absolute;
  right: 6px;
  top: 6px;
  opacity: 0.3;
}

.switcher.expanded {
  overflow: visible;
  background-color: #fff;
}
.switcher-option {
  height: 40px;
  line-height: 40px;
  padding: 0 6px;
  cursor: pointer;
  font-size: 30px;
  font-family: 'Barlow Condensed';
}
.switcher.expanded .switcher-option {
  background-color: #fff;
  position: static;
  top: auto;
  border-radius: 5px;
  box-shadow: #0003 0 2px 6px;
}

.switcher-option.current {
  display: none;
}

.site-switcher-loading {
  height: 40px;
  overflow: hidden;
  position: relative;
  z-index: 40;
  border: 1px solid #bbb;
  border-radius: 5px;
  max-width: 400px;
  margin-right: 6px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 19px;
  color: #777;
}

.toggle-wrap{line-height:40px;}
.btn.btn-toggle{ border-color:#aaa }
.btn.btn-toggle-on{ 
  border-color: #000;
  box-shadow: rgb(0 0 0 / 7%) 0 0 0 3px;
}

.expander{ position:relative; }
.expander-icon {
  position: absolute;
  top: 4px;
  right: 0;
  height: 36px;
  width: 36px;
}
.expander-icon svg {
  width: 36px;
  height: 36px;
  fill: #aaa;
}
.expander .expander-body{display:none}
.expander-body {
  border-top: 1px solid #eee;
  padding-top: 4px;
}
.expander.open .expander-body{display:block}