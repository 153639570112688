body,
html {
  height: 100%;
}

.m0,
.p-name,
p.m0 {
  margin: 0;
}

.ma {
  margin: 0 auto;
}

.e-content p,
.mb,
blockquote,
pre {
  margin-bottom: 1rem;
}

html {
  scroll-behavior: smooth;
}

.mb1 {
  margin-bottom: 1rem;
}

.mbs,
h1,
h3,
h4 {
  margin-bottom: 0.4rem;
}

.comment p,
.mb0 {
  margin-bottom: 0;
}

.mt,
blockquote {
  margin-top: 1rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mrs {
  margin-right: 0.4rem;
}

.bx.p0,
.p0 {
  padding: 0;
}

.bx,
.f,
.p1,
blockquote,
pre {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pxs {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.ps {
  padding: 0.4rem;
}

.br6 {
  border-radius: 0.6rem;
}

.bg-c {
  background-position: center;
  background-size: cover;
  background-color: #f3f0e6;
}

.wrap {
  max-width: 1088px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}

.wrap::after {
  display: block;
  clear: both;
  content: "";
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.col {
  flex-direction: column;
  justify-content: space-between;
}

.flex.nw {
  flex-wrap: nowrap;
}

.end {
  align-self: flex-end;
}

.aic {
  align-items: center;
}

.fill,
.fx1 {
  flex: 1;
}

.main,
.sb {
  width: 100%;
  margin-bottom: 1.5rem;
}

.c1_2 {
  flex: 0 0 49%;
}

.c1_3 {
  flex: 0 0 32%;
}

.c1_4 {
  flex: 0 0 23.5%;
}

.md-c1_2,
.md-c1_3,
.md-c1_4,
.md-c2_3 {
  display: block;
  width: 100%;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

#dw-cb,
.d-none,
.dw,
.nav-menu {
  display: none;
}

.icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .md-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .md-c1_2 {
    flex: 0 0 49%;
    max-width: 49%;
  }

  .md-c1_3 {
    flex: 0 0 32%;
    max-width: 32%;
  }

  .md-c2_3 {
    flex: 0 0 64%;
    max-width: 64%;
  }

  .md-c1_4 {
    flex: 0 0 23.5%;
    max-width: 23.5%;
  }

  .d-md-block {
    display: block;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-none {
    display: none;
  }
}

@media (min-width: 1073px) {
  .wrap {
    max-width: 1073px;
  }

  .main {
    flex: 0 0 728px;
    max-width: 728px;
  }

  .sb {
    flex: 0 0 300px;
    max-width: 300px;
  }

  .sbfx {
    position: sticky;
    top: 75px;
  }
}

.mw100 {
  min-width: 100%;
}

.h120 {
  height: 120px;
}

.h150 {
  height: 150px;
}

.h180 {
  height: 180px;
}

.h240 {
  height: 240px;
}

.w460 {
  max-width: 460px;
}

.z0,
.bx.z0 {
  box-shadow: none;
}

.sticky {
  position: sticky;
  top: 60px;
  box-shadow: #0003 0 2px 6px
}