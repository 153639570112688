:root {
  --accent: #ff9800;
  --chroma: #707070;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  list-style: none;
  line-height: 1;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

a,
h3 a.url {
  color: #111;
}

.text a {
  color: #00e;
  text-decoration: underline;
  font-size: 95%;
}

a.admin {
  color: red;
  font-weight: 700;
}

.p-name a {
  color: #333;
}

.p-name a:hover {
  color: #00e;
}

code {
  background: #eee;
  padding: 1px 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 95%;
}

pre {
  background: #eee;
  max-width: 100%;
  overflow: auto;
}

.bx {
  background: #fff;
  box-shadow: 0 2px 4px rgba(32, 33, 36, 0.28);
  color: #222;
  border-radius: 8px;
}

.flat {
  box-shadow: none;
}

.well {
  background: #f6f6f6;
  box-shadow: inset 0 2px 4px rgba(32, 33, 36, 0.16);
  border-radius: 8px;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 30px;
}

.sr {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

body {
  overflow-x: hidden;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.br {
  border-radius: 0.6rem;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.fwr {
  flex-wrap: wrap;
}

.jcc {
  justify-content: center;
}



img {
  max-width: 100%;
  height: auto;
}

.menu a {
  display: inline-block;
  margin: 10px;
}

.ofh {
  overflow: hidden;
}


/*! colors */
.bg-warn {
  background: #f4e0c7;
}

.bg-info {
  background: #c8f3ee;
}

.bg-danger {
  background: #f3d7d7;
}

.err {
  color: #9e0000;
}

.pagination {
  margin: 30px auto;
  text-align: center;
}

.page-item {
  display: inline-block;
  margin: 2px
}

.page-item button {
  border: 0;
  box-shadow: none;
  min-width: 45px;
  padding: 12px 0;
}

.page-item.active button {
  background: #333;
  color: #fff
}